<script lang="ts" setup>
import { computed, ref } from 'vue'

type ElType = any

const cachedStyles = ref<{ [key: string]: any } | null>(null)
const dimension = 'height'
const duration = 300
const easing = 'ease-in-out'

const transitionValue = computed(() => {
  const transitions: string[] = []

  if (cachedStyles.value)
    Object.keys(cachedStyles.value).forEach((key) => {
      transitions.push(`${convertToCssProperty(key)} ${duration}ms ${easing}`)
    })

  return transitions.join(', ')
})

const enter = (el: ElType, done: () => void) => {
  // Because width and height may be 'auto',
  // first detect and cache the dimensions
  detectAndCacheDimensions(el)

  // The order of applying styles is important:
  // - 1. Set styles for state before transition
  // - 2. Force repaint
  // - 3. Add transition style
  // - 4. Set styles for state after transition
  // If the order is not right and you open any 2nd level submenu
  // for the first time, the transition will not work.
  setClosedDimensions(el)
  hideOverflow(el)
  forceRepaint(el)
  setTransition(el)
  setOpenedDimensions(el)

  // Emit the event to the parent
  // this.$emit('enter', el, done)

  // Call done() when the transition ends
  // to trigger the @after-enter event.
  setTimeout(done, duration)
}
const afterEnter = (el: ElType) => {
  // Clean up inline styles
  unsetOverflow(el)
  unsetTransition(el)
  unsetDimensions(el)
  clearCachedDimensions()

  // Emit the event to the parent
  // this.$emit('after-enter', el)
}
const leave = (el: ElType, done: () => void) => {
  // For some reason, @leave triggered when starting
  // from open state on page load. So for safety,
  // check if the dimensions have been cached.
  detectAndCacheDimensions(el)

  // The order of applying styles is less important
  // than in the enter phase, as long as we repaint
  // before setting the closed dimensions.
  // But it is probably best to use the same
  // order as the enter phase.
  setOpenedDimensions(el)
  hideOverflow(el)
  forceRepaint(el)
  setTransition(el)
  setClosedDimensions(el)

  // Emit the event to the parent
  // this.$emit('leave', el, done)

  // Call done() when the transition ends
  // to trigger the @after-leave event.
  // This will also cause v-show
  // to reapply 'display: none'.
  setTimeout(done, duration)
}

const afterLeave = (el: ElType) => {
  // Clean up inline styles
  unsetOverflow(el)
  unsetTransition(el)
  unsetDimensions(el)
  clearCachedDimensions()

  // Emit the event to the parent
  // this.$emit('after-leave', el)
}

const detectAndCacheDimensions = (el: ElType) => {
  // Cache actual dimensions
  // only once to void invalid values when
  // triggering during a transition
  if (cachedStyles.value) return

  const visibility = el.style.visibility
  const display = el.style.display

  // Trick to get the width and
  // height of a hidden element
  el.style.visibility = 'hidden'
  el.style.display = ''

  cachedStyles.value = detectRelevantDimensions(el)

  // Restore any original styling
  el.style.visibility = visibility
  el.style.display = display
}
const clearCachedDimensions = () => {
  cachedStyles.value = null
}

const detectRelevantDimensions = (el: ElType) => {
  // These properties will be transitioned
  if (dimension === 'height') {
    return {
      height: el.offsetHeight + 'px',
      paddingTop: el.style.paddingTop || getCssValue(el, 'padding-top'),
      paddingBottom: el.style.paddingBottom || getCssValue(el, 'padding-bottom')
    }
  }

  if (dimension === 'width') {
    return {
      width: el.offsetWidth + 'px',
      paddingLeft: el.style.paddingLeft || getCssValue(el, 'padding-left'),
      paddingRight: el.style.paddingRight || getCssValue(el, 'padding-right')
    }
  }

  return {}
}

const setTransition = (el: ElType) => {
  el.style.transition = transitionValue.value
}
const unsetTransition = (el: ElType) => {
  el.style.transition = ''
}

const hideOverflow = (el: ElType) => {
  el.style.overflow = 'hidden'
}

const unsetOverflow = (el: ElType) => {
  el.style.overflow = ''
}

const setClosedDimensions = (el: ElType) => {
  if (cachedStyles.value)
    Object.keys(cachedStyles.value).forEach((key) => {
      el.style[key] = '0'
    })
}

const setOpenedDimensions = (el: ElType) => {
  if (cachedStyles.value)
    Object.keys(cachedStyles.value).forEach((key) => {
      el.style[key] = cachedStyles.value?.[key]
    })
}

const unsetDimensions = (el: ElType) => {
  if (cachedStyles.value)
    Object.keys(cachedStyles.value).forEach((key) => {
      el.style[key] = ''
    })
}

const forceRepaint = (el: ElType) => {
  // Force repaint to make sure the animation is triggered correctly.
  return getComputedStyle(el)[dimension]
}

const getCssValue = (el: ElType, style: string) => {
  return getComputedStyle(el, null).getPropertyValue(style)
}

const convertToCssProperty = (style: string) => {
  // Example: convert 'paddingTop' to 'padding-top'
  // Thanks: https://gist.github.com/tan-yuki/3450323
  const upperChars = style.match(/([A-Z])/g)

  if (!upperChars) {
    return style
  }

  for (let i = 0, n = upperChars.length; i < n; i++) {
    style = style.replace(new RegExp(upperChars[i]), '-' + upperChars[i].toLowerCase())
  }

  if (style.slice(0, 1) === '-') {
    style = style.slice(1)
  }

  return style
}
</script>
<template>
  <Transition
    name="collapse"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </Transition>
</template>
